/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import { Page, Searchbar, Table } from '../../design';
import { useRequester } from '../../hooks';
import { useEffect, useState, useContext } from 'react';
import { ChatResource, StudentData } from '../../resources';
import { AuthContext } from '../../contexts';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { formatDate, formatTime } from '../../utils';

interface Row {
  id: string;
  type: string;
  difficulty: string;
  duration: number;
  createdAt: string;
  student: StudentData;
  meta: any;
}

type Query = {
  offset: number;
  limit: number;
};

function NameCell(props: Row) {
  return (
    <Box className="flex items-center gap-4">
      <Avatar src={props.student.avatar} />
      <Typography variant="h6" color="text.primary">
        {props.student.firstName} {props.student.lastName}
      </Typography>
    </Box>
  );
}

function TypeCell(props: Row) {
  return (
    <Box className="flex flex-col items-center gap-1">
      <Typography variant="h6" color="text.primary">
        Eiken2
      </Typography>
      <Typography variant="h6" color="text.secondary">
        {props.meta?.eikenTestType}
      </Typography>
    </Box>
  );
}

const columns = [
  {
    id: 'student',
    label: 'Student',
    Component: NameCell,
  },
  {
    id: 'type',
    label: 'Type',
    Component: TypeCell,
  },
  {
    id: 'difficulty',
    label: 'Difficulty',
    align: 'center',
  },
  {
    id: 'createdAt',
    label: 'Created At',
    align: 'center',
  },
  {
    id: 'duration',
    label: 'Duration',
    align: 'center',
    format: (value: number) => `${formatTime(value)}`,
  },
];

const toRow = (ob: ChatResource): Row => {
  return {
    id: ob.data.id,
    type: ob.data.type,
    meta: ob.data.meta,
    difficulty: ob.data.difficulty,
    duration: ob.data.duration,
    createdAt: formatDate(ob.data.createdAt),
    student: ob.data.student,
  };
};

export default function ChatsPage() {
  const { user } = useContext(AuthContext);
  const [params] = useSearchParams();
  const userId = params.get('userId');
  const navigate = useNavigate();

  const [hasReachedBottom, setHasReachedBottom] = useState(false);
  const [query, setQuery] = useState<Query>({
    offset: 0,
    limit: 50,
  });
  const chatsApi = useRequester<ChatResource[]>({
    onSubmit: (q) => ChatResource.fetchMany(q),
    onSuccess: (data) => setHasReachedBottom(data.length < query.limit),
    onError: console.log,
  });

  useEffect(() => {
    chatsApi.submit({
      ...query,
      userId,
      type: 'eiken2',
      includeOthers: true,
    });
  }, [query.offset, userId]);

  const rows = ChatResource.list().map(toRow);

  return (
    <Page>
      <Page.Header>
        <Box className="flex flex-col h-full">
          <Typography variant="body1" color="text.secondary" sx={{ mt: 8 }}>
            👋 Hey, {user?.data.firstName}!
          </Typography>
          <Typography variant="h1">
            Welcome to your practice dashboard
          </Typography>
          <Box className="flex-grow"></Box>
        </Box>
      </Page.Header>
      <Page.Content>
        <Box className="flex justify-between mt-8 mb-8">
          <Box className="flex gap-4">
            <Searchbar
              disabled
              placeholder="Search for a practice"
              onChange={(qs) => {}}
            />

            {chatsApi.loading && (
              <Box className="flex items-center">
                <CircularProgress size={20} />
              </Box>
            )}
          </Box>
        </Box>

        <Table
          rows={rows}
          // @ts-ignore
          columns={columns}
          onRowClick={(row) => navigate(`/chats/${row.id}`)}
        />
        {!hasReachedBottom && rows.length > 0 && (
          <Button
            disabled={chatsApi.loading}
            sx={{ mt: 2 }}
            fullWidth
            onClick={() => setQuery({ ...query, offset: query.offset + 50 })}
          >
            Load More
          </Button>
        )}
      </Page.Content>
    </Page>
  );
}
