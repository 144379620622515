import { Card, Typography, Box } from '@mui/material';

export interface Benchmark {
  label: string;
  score: string;
  maxScore?: number;
}

interface Props {
  benchmarks: Benchmark[];
}

export default function BenchmarkCard({ benchmarks }: Props) {
  return (
    <Card sx={{ borderRadius: '12px' }} className="flex flex-col gap-2">
      <Typography variant="h6">Benchmark (Equivalent to)</Typography>
      <Box className="flex flex-row gap-2">
        {benchmarks.map((benchmark) => (
          <Box
            key={benchmark.label}
            className="flex flex-col gap-3 flex-grow"
            sx={{
              border: '1px solid',
              borderColor: 'border.1',
              backgroundColor: 'background.2',
              p: 2,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6">{benchmark.label}</Typography>
            <Typography variant="h3">
              {benchmark.score}
              {benchmark.maxScore && <>/{benchmark.maxScore}</>}
            </Typography>
          </Box>
        ))}
      </Box>
    </Card>
  );
}
