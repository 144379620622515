import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import ChatResource, {
  ChatData,
  FeedbackData,
  ParsedScore,
} from '../../resources/ChatResource';
import ScoreCard from './ScoreCard';

interface Props {
  type: string;
  chat: ChatData;
}

function extractData(feedbacks: FeedbackData[]): {
  summary: string;
  scores: ParsedScore[];
  grammarFeedback: string;
} {
  const summary = feedbacks.find((feedback) => feedback.type === 'summary');

  const grammarFeedback = feedbacks.find(
    (feedback) => feedback.type === 'grammarFeedback'
  );

  return {
    summary: summary?.content || summary?.contentJp || '',
    scores: ChatResource.parseScores(
      ['arousal', 'speechPace', 'valence', 'eyeContact'],
      feedbacks
    ),
    grammarFeedback:
      grammarFeedback?.content || grammarFeedback?.contentJp || '',
  };
}

export default function QuestionTab({ type, chat }: Props) {
  const feedbacks = chat.feedbacks.filter(
    (feedback) => feedback.section === type
  );

  const { summary, scores, grammarFeedback } = extractData(feedbacks);

  return (
    <Box className="flex flex-col gap-8">
      {type === 'q2' && (
        <Box
          component="img"
          src={chat.image}
          alt="Question"
          sx={{ height: '400px', width: '600px' }}
        />
      )}
      <Box className="flex flex-col gap-4">
        <Typography variant="h2">Summary</Typography>
        <Typography variant="body1" whiteSpace="break-spaces">
          {summary}
        </Typography>
      </Box>
      <Box className="flex flex-col gap-4">
        <Typography variant="h2">Grammar Feedback</Typography>
        <Typography variant="body1" whiteSpace="break-spaces">
          {grammarFeedback}
        </Typography>
      </Box>
      <Box className="flex flex-col gap-4">
        <Typography variant="h2">Scores</Typography>
        <Box className="grid grid-cols-2 gap-4">
          {scores.map((score) => (
            <ScoreCard key={score.label} score={score} />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
