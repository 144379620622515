import axios from 'axios';
import cookie from 'js-cookie';
import { Dict } from '../types';
import { IDataContext, StoreActionType } from '../contexts/DataContext';
import { ISnackbar } from '../hooks/useSnackbar';

// Todo: read from env var
const API_ROOT = process.env.REACT_APP_API_ROOT || 'http://localhost:8080';
export enum AppScope {
  Lite = 'lite',
  LiteBeginner = 'lite-beginner',
  VR = 'vr',
}

export enum UserRole {
  Admin = 'admin',
  Teacher = 'teacher',
  User = 'user',
}

export const SCOPE_LABEL_MAP = {
  [AppScope.Lite]: 'Lite',
  [AppScope.LiteBeginner]: 'Lite Beginner',
  [AppScope.VR]: 'VR',
};

interface Data {
  id: string;
}

class BaseResource<T extends Data> {
  private static cancelTokens: Dict<any> = {};
  protected static store: IDataContext;
  protected static snackbar: ISnackbar;

  public data: T;

  constructor(data: T) {
    this.data = data;
  }

  static get storeName(): string {
    throw new Error('Store name not defined');
  }

  static get endpoint(): string {
    throw new Error('Endpoint not defined');
  }

  static get scope(): AppScope {
    return (localStorage.getItem('scope') as AppScope) || 'lite';
  }

  static setScope(scope: AppScope) {
    localStorage.setItem('scope', scope);
    window.location.href = '/';
  }

  private static async httpRequest(
    method: 'get' | 'post' | 'patch' | 'delete',
    endpoint: string,
    ...args: any[]
  ) {
    const url = API_ROOT + endpoint;
    this.cancelTokens[method + ' ' + url]?.cancel();

    const source = axios.CancelToken.source();
    this.cancelTokens[method + ' ' + url] = source;

    if (args.length > 0) {
      args[args.length - 1]['cancelToken'] = source.token;

      const headers = args[args.length - 1]['headers'] || {};
      headers['Authorization'] = `bearer ${cookie.get('token')}`;
      args[args.length - 1]['headers'] = headers;
    }

    try {
      const res = await axios[method](url, ...args);
      delete this.cancelTokens[method + ' ' + url];
      return res.data;
    } catch (err: any) {
      if (err?.response?.status === 401) cookie.remove('token');
      throw err;
    }
  }

  static get http() {
    return {
      get: (endpoint: string, params?: any) =>
        this.httpRequest('get', endpoint, { params }),
      post: (endpoint: string, params?: any, body?: any, headers?: any) =>
        this.httpRequest('post', endpoint, body, { params, headers }),
      patch: (endpoint: string, params?: any, body?: any) =>
        this.httpRequest('patch', endpoint, body, { params }),
      delete: (endpoint: string, params?: any) =>
        this.httpRequest('delete', endpoint, { params }),
    };
  }

  static async fetchMany(q: any = {}) {
    const raw: any = await this.http.get(this.endpoint, q);
    const objects = this.upsertManyAndReturn(raw);

    const key = this.storeName + 'CachedQuery';
    let ids = this.store.state[key] as any as string[];

    // Handling infinite scroll
    if (!q.offset) {
      ids = objects.map((obj) => obj.data.id);
    } else {
      for (let index = 0; index < objects.length; index++) {
        const obj = objects[index];
        ids[index + q.offset] = obj.data.id;
      }
    }

    // Remove nulls, just in case
    this.cacheQueryResult(ids.filter((id) => id !== undefined));

    return objects;
  }

  static async fetchOne(id: string) {
    const raw: any = await this.http.get(`${this.endpoint}/${id}`);
    return this.upsertAndReturn(raw);
  }

  static async createOne(data: any) {
    const raw: any = await this.http.post(this.endpoint, {}, data);
    return this.upsertAndReturn(raw);
  }

  static async updateOne(id: string, data: any) {
    const raw: any = await this.http.patch(`${this.endpoint}/${id}`, {}, data);
    return this.upsertAndReturn(raw);
  }

  static async deleteOne(id: string) {
    await this.http.delete(`${this.endpoint}/${id}`);
    return this.deleteAndReturn(id);
  }

  static list() {
    const key = this.storeName + 'CachedQuery';
    const ids = this.store.state[key] as any as string[];
    const data = ids.map((id: string) => this.store.state[this.storeName][id]);
    return data.map((item: any) => new this(item));
  }

  static all() {
    const key = this.storeName;
    const data = this.store.state[key] as any;
    return Object.values(data).map((item: any) => new this(item));
  }

  static get(id: string) {
    const data = this.store.state[this.storeName][id] as any;
    return new this(data);
  }

  updateStore() {
    const cls = this.constructor as typeof BaseResource;
    BaseResource.store.dispatch({
      type: StoreActionType.Upsert,
      target: cls.storeName,
      payload: this.data,
    });
  }

  protected static cacheQueryResult(ids: string[]) {
    BaseResource.store.dispatch({
      type: StoreActionType.CacheQuery,
      target: this.storeName,
      payload: ids,
    });
  }

  protected static upsertAndReturn(raw: any) {
    const object = this.toObject(raw);

    BaseResource.store.dispatch({
      type: StoreActionType.Upsert,
      target: this.storeName,
      payload: object.data,
    });

    return object;
  }

  protected static upsertManyAndReturn(raw: any[]) {
    const objects = raw.map((item: any) => this.toObject(item));

    BaseResource.store.dispatch({
      type: StoreActionType.UpsertMany,
      target: this.storeName,
      payload: objects.map((item: any) => item.data),
    });

    return objects;
  }

  protected static deleteAndReturn(id: string) {
    BaseResource.store.dispatch({
      type: StoreActionType.Delete,
      target: this.storeName,
      payload: id,
    });
  }

  static setStore(store: IDataContext) {
    BaseResource.store = store;
  }

  static setSnackbar(snackbar: ISnackbar) {
    BaseResource.snackbar = snackbar;
  }

  static toObject(raw: any): BaseResource<any> {
    throw new Error('fromRaw not implemented');
  }

  static showAlert(message: string, severity: 'success' | 'error' = 'success') {
    this.snackbar.showAlert(message, severity);
  }

  static async upload(
    file: File,
    type: 'image' | 'video' | 'audio'
  ): Promise<string> {
    const formData = new FormData();
    formData.append('file', file);

    const result = await this.http.post(`/upload/${type}`, {}, formData, {
      'Content-Type': 'multipart/form-data',
    });

    return result.location;
  }
}

export default BaseResource;
