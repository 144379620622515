import { ArticleData } from './ArticleResource';
import BaseResource from './BaseResource';
import { StudentData } from './StudentResource';
import { TutorData } from './TutorResource';

interface ChatLine {
  text: string;
  isUser: boolean;
  meta: any;
  audio: string;
}

export interface FeedbackMetricValue {
  raw: number;
  level: {
    value: number;
    description: {
      en: string;
      ja: string;
    };
  };
}

interface FeedbackMetric {
  key: string;
  value: string | FeedbackMetricValue;
}

export interface FeedbackData {
  chatId: string;
  content: string;
  contentJp: string;
  type: string;
  metrics: FeedbackMetric[];
  section: string;
  createdAt: string;
}

export interface ChatData {
  id: string;
  image: string;
  lines: ChatLine[];
  missions: any[];
  tutor: TutorData;
  difficulty: string;
  news: ArticleData;
  duration: number;
  createdAt: string;
  type: string;
  meta: any;
  feedbacks: FeedbackData[];
  userId: string;
  student: StudentData;
}

export interface ParsedScore {
  label: string;
  percentage: string;
  score: string;
  description: string;
  type?: string;
  maxScore?: number;
  unit?: string;
  name: string;
}

const defaultConfigMap = {
  arousal: {
    name: 'arousal',
    label: 'Energy',
    unit: '%',
  },
  speechPace: {
    name: 'speechPace',
    label: 'Speech Pace',
    unit: 'wpm',
  },
  valence: {
    name: 'valence',
    label: 'Attitude',
    unit: '%',
  },
  eyeContact: {
    name: 'eyeContact',
    label: 'Eye Contact',
    unit: '%',
  },
  clarity: {
    name: 'clarity',
    label: 'Clarity',
    unit: '%',
  },
  pronunciation: {
    name: 'pronunciation',
    label: 'Pronunciation',
    unit: '%',
  },
};

class ChatResource extends BaseResource<ChatData> {
  static get storeName(): string {
    return 'ChatResource';
  }

  static get endpoint(): string {
    return `/${this.scope}/chats`;
  }

  static async fetchLatestChatsForUser(
    userId: string
  ): Promise<ChatResource[]> {
    const raw = await this.http.get(
      `/${this.scope}/chats?userId=${userId}&limit=4&includeOthers=true`
    );
    return this.upsertManyAndReturn(raw);
  }

  static listLatestChatsForUser(userId: string): ChatResource[] {
    const chats = this.all().filter((chat) => chat.data.userId === userId);
    return chats
      .sort((a, b) => b.data.createdAt - a.data.createdAt)
      .slice(0, 4);
  }

  static toObject(raw: any): ChatResource {
    return new ChatResource(raw as ChatData);
  }

  static parseScores(
    names: string[],
    feedbacks: FeedbackData[],
    maxScore?: number
  ): ParsedScore[] {
    const metrics = feedbacks.flatMap((feedback) => feedback.metrics);
    const scores = metrics.filter((metric) => names.includes(metric.key));

    const parsedScores = scores.map((score) => {
      const parsed = score.value as FeedbackMetricValue;

      // Clarity and Eye Contact are not scored
      const computedMaxScore = ['clarity', 'eyeContact'].includes(score.key)
        ? undefined
        : maxScore;

      if (parsed.raw < 0) {
        return {
          ...defaultConfigMap[score.key as keyof typeof defaultConfigMap],
          percentage: '0',
          score: '-',
          description: 'No data available',
          maxScore: computedMaxScore,
        };
      }

      return {
        ...defaultConfigMap[score.key as keyof typeof defaultConfigMap],
        percentage: parsed.raw.toFixed(0),
        score: parsed.level.value.toFixed(0),
        type: parsed.raw > 50 ? 'pass' : 'fail',
        description: parsed.level.description.en,
        maxScore: computedMaxScore,
      };
    });

    // Order parsedScores by the order of names
    return parsedScores.sort(
      (a, b) => names.indexOf(a.name) - names.indexOf(b.name)
    );
  }
}

export default ChatResource;
