import { Box, Typography } from '@mui/material';
import ChatResource, {
  ChatData,
  FeedbackData,
  ParsedScore,
} from '../../resources/ChatResource';
import ScoreCard from './ScoreCard';
import BenchmarkCard, { Benchmark } from './BenchmarkCard';
import Phonemes, { Phoneme } from './Phonemes';

interface Props {
  chat: ChatData;
  referenceText: string;
}

function extractData(feedbacks: FeedbackData[]): {
  scores: ParsedScore[];
  benchmarks: Benchmark[];
  phonemes: Phoneme[];
  clarityBenchmarks: {
    pauses: any;
    wordCorrectness: any;
    syllableCorrectness: any;
  };
} {
  const benchmarks = {
    toeicScore: {
      label: 'TOEIC',
      score: '-',
      maxScore: 200,
    },
    ieltsScore: {
      label: 'IELTS',
      score: '-',
      maxScore: 9,
    },
    cefrScore: {
      label: 'CFER',
      score: '-',
    },
  };

  const metrics = feedbacks.flatMap((feedback) => feedback.metrics);

  const correctWords = metrics.find(
    (metric) => metric.key === 'correctWords'
  )?.value;
  const totalWords = metrics.find(
    (metric) => metric.key === 'totalWords'
  )?.value;
  const correctSyllables = metrics.find(
    (metric) => metric.key === 'correctSyllables'
  )?.value;
  const totalSyllables = metrics.find(
    (metric) => metric.key === 'totalSyllables'
  )?.value;

  const clarityBenchmarks = {
    pauses: {
      label: 'Pauses',
      value: metrics.find((metric) => metric.key === 'pauses')?.value || '-',
    },
    wordCorrectness: {
      label: 'Word Correctness',
      correct: correctWords || '-',
      total: totalWords || '-',
      value:
        typeof correctWords === 'number' &&
        typeof totalWords === 'number' &&
        totalWords > 0
          ? ((correctWords / totalWords) * 100).toFixed(1) + '%'
          : '-',
    },
    syllableCorrectness: {
      label: 'Syllable Correctness',
      correct: correctSyllables || '-',
      total: totalSyllables || '-',
      value:
        typeof correctSyllables === 'number' &&
        typeof totalSyllables === 'number' &&
        totalSyllables > 0
          ? ((correctSyllables / totalSyllables) * 100).toFixed(1) + '%'
          : '-',
    },
  };

  for (const metric of metrics) {
    const key = metric.key as keyof typeof benchmarks;
    if (!benchmarks[key]) continue;
    benchmarks[key].score = metric.value as string;
  }

  const phonemes = metrics.find((metric) => metric.key === 'phonemes');

  return {
    scores: ChatResource.parseScores(
      [
        'pronunciation',
        'valence',
        'clarity',
        'speechPace',
        'arousal',
        'eyeContact',
      ],
      feedbacks,
      5
    ),
    benchmarks: Object.values(benchmarks),
    phonemes: phonemes?.value as any as Phoneme[],
    clarityBenchmarks,
  };
}

export default function PronunciationTab({ chat, referenceText }: Props) {
  const { scores, benchmarks, phonemes, clarityBenchmarks } = extractData(
    chat.feedbacks.filter((feedback) => feedback.section === 'pronunciation')
  );

  return (
    <Box className="flex flex-col gap-8">
      <Box className="flex flex-col gap-6">
        <Typography variant="h2">Paragraph</Typography>
        <Phonemes referenceText={referenceText} phonemes={phonemes} />
      </Box>
      <Box className="flex flex-col gap-4">
        <Typography variant="h2">Scores</Typography>
        <Box className="grid grid-cols-2 gap-4">
          {scores.map((score) => (
            <ScoreCard key={score.label} score={score}>
              {score.name === 'clarity' && (
                <Box className="flex flex-row gap-2">
                  <Box
                    className="flex flex-col gap-3 flex-grow"
                    sx={{
                      border: '1px solid',
                      borderColor: 'border.1',
                      backgroundColor: 'background.2',
                      p: 2,
                      borderRadius: 2,
                    }}
                  >
                    <Typography variant="h6">
                      {clarityBenchmarks.pauses.label}
                    </Typography>
                    <Typography variant="h3">
                      {clarityBenchmarks.pauses.value}
                    </Typography>
                  </Box>
                  <Box
                    className="flex flex-col gap-3 flex-grow"
                    sx={{
                      border: '1px solid',
                      borderColor: 'border.1',
                      backgroundColor: 'background.2',
                      p: 2,
                      borderRadius: 2,
                    }}
                  >
                    <Typography variant="h6">
                      {clarityBenchmarks.wordCorrectness.label}
                    </Typography>
                    <Box className="flex flex-row gap-2 items-end">
                      <Typography variant="h3">
                        {clarityBenchmarks.wordCorrectness.value}
                      </Typography>
                      <Typography variant="body1">
                        ({clarityBenchmarks.wordCorrectness.correct}/
                        {clarityBenchmarks.wordCorrectness.total})
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    className="flex flex-col gap-3 flex-grow"
                    sx={{
                      border: '1px solid',
                      borderColor: 'border.1',
                      backgroundColor: 'background.2',
                      p: 2,
                      borderRadius: 2,
                    }}
                  >
                    <Typography variant="h6">
                      {clarityBenchmarks.syllableCorrectness.label}
                    </Typography>
                    <Box className="flex flex-row gap-2 items-end">
                      <Typography variant="h3">
                        {clarityBenchmarks.syllableCorrectness.value}
                      </Typography>
                      <Typography variant="body1">
                        ({clarityBenchmarks.syllableCorrectness.correct}/
                        {clarityBenchmarks.syllableCorrectness.total})
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </ScoreCard>
          ))}
          <BenchmarkCard benchmarks={benchmarks} />
        </Box>
      </Box>
    </Box>
  );
}
