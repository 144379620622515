import { Container } from '@mui/material';

import { Box } from '@mui/material';

export function Page({ children }: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 64px)',
        backgroundColor: 'background.2',
      }}
    >
      {children}
    </Box>
  );
}

function PageHeader({ children }: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        height: '228px',
        backgroundColor: 'background.1',
        borderBottom: '1px solid',
        borderColor: 'border.1',
      }}
    >
      <Container sx={{ height: '100%' }} maxWidth="xl">
        {children}
      </Container>
    </Box>
  );
}

function PageContent({ children }: { children: React.ReactNode }) {
  return (
    <Container sx={{ height: '100%', pb: 4 }} maxWidth="xl">
      {children}
    </Container>
  );
}

Page.Header = PageHeader;
Page.Content = PageContent;
