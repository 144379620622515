/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useRequester } from '../../hooks';
import { DateRange, LineChart, Page, PassFail } from '../../design';
import { StudentData, StudentResource } from '../../resources';
import StatisticResource, {
  Aggregation,
  Statistic,
} from '../../resources/StatisticResource';
import { plColors } from '../..';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ChatResource, {
  ChatData,
  FeedbackMetricValue,
} from '../../resources/ChatResource';
import { formatDate } from '../../utils';
import { ArrowBack } from '@mui/icons-material';

function ChatCard({
  chat,
  section,
  compact,
  onClick,
}: {
  chat: ChatData;
  section?: string;
  compact?: boolean;
  onClick?: () => void;
}) {
  let title;
  let passScore;
  let maxScore;
  let score;

  const type = section || chat.meta?.eikenTestType;

  switch (type) {
    case 'fulltest':
      title = 'Full test';
      passScore = 23;
      maxScore = 33;
      score = chat.feedbacks
        .find(
          (feedback) =>
            feedback.type === 'overallFeedback' &&
            feedback.section === 'fulltest'
        )
        ?.metrics.find((metric) => metric.key === 'totalScore')
        ?.value as string;
      break;
    case 'pronunciation':
      title = 'Pronunciation';
      passScore = 4;
      maxScore = 5;
      score = (
        chat.feedbacks
          .find(
            (feedback) =>
              feedback.type === 'speechFeedback' &&
              feedback.section === 'pronunciation'
          )
          ?.metrics.find((metric) => metric.key === 'pronunciation')
          ?.value as FeedbackMetricValue
      ).level.value;
      break;
    case 'q1':
      title = 'Q1';
      passScore = 4;
      maxScore = 5;
      score = chat.feedbacks
        .find(
          (feedback) => feedback.type === 'summary' && feedback.section === type
        )
        ?.metrics.find((metric) => metric.key === 'score')?.value as string;
      break;
    case 'q2':
      title = 'Q2';
      passScore = 8;
      maxScore = 10;
      score = chat.feedbacks
        .find(
          (feedback) => feedback.type === 'summary' && feedback.section === type
        )
        ?.metrics.find((metric) => metric.key === 'score')?.value as string;
      break;
    case 'q3':
      title = 'Q3';
      passScore = 4;
      maxScore = 5;
      score = chat.feedbacks
        .find(
          (feedback) => feedback.type === 'summary' && feedback.section === type
        )
        ?.metrics.find((metric) => metric.key === 'score')?.value as string;
      break;
    case 'q4':
      title = 'Q4';
      passScore = 4;
      maxScore = 5;
      score = chat.feedbacks
        .find(
          (feedback) => feedback.type === 'summary' && feedback.section === type
        )
        ?.metrics.find((metric) => metric.key === 'score')?.value as string;
      break;
    default:
      title = 'N/A';
  }

  const timestamp = formatDate(chat.createdAt);

  let passfailType;

  if (Number(score) && passScore) {
    passfailType = Number(score) >= passScore ? 'pass' : 'fail';
  }

  return (
    <Card
      sx={{
        borderRadius: 3,
        p: compact ? 2 : 3,
        flexDirection: compact ? 'column' : 'row',
        justifyContent: compact ? 'flex-start' : 'space-between',
        gap: 2,
        cursor: onClick ? 'pointer' : 'default',
      }}
      className="flex justify-between"
      onClick={onClick}
    >
      <Box className="flex flex-col">
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body2" color="text.secondary">
          {timestamp}
        </Typography>
      </Box>
      {score !== undefined && (
        <Box className="flex flex-row justify-between items-center gap-4">
          <Typography variant="h3">
            {score || '-'}/{maxScore}
          </Typography>
          <PassFail type={passfailType} />
        </Box>
      )}
    </Card>
  );
}

export default function StudentPage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [aggregation, setAggregation] = useState<Aggregation>(Aggregation.Week);
  const [to, setTo] = useState<Date>(new Date());

  const from =
    aggregation === Aggregation.Week
      ? moment(to).subtract(12, 'weeks').toDate()
      : moment(to).subtract(12, 'months').toDate();

  const studyTimeApi = useRequester<StatisticResource[]>({
    onSubmit: (q) =>
      StatisticResource.fetchStatistic(Statistic.EikenStudyTimes, q),
    onError: console.log,
  });

  const scoreApi = useRequester<StatisticResource[]>({
    onSubmit: (q) => StatisticResource.fetchStatistic(Statistic.EikenScores, q),
    onError: console.log,
  });

  const latestChatsApi = useRequester<ChatResource[]>({
    onSubmit: (q) => ChatResource.fetchLatestChatsForUser(q),
    onError: console.log,
  });

  useEffect(() => {
    latestChatsApi.submit(id);
  }, [id]);

  useEffect(() => {
    const query = {
      aggregation,
      from,
      to,
      userId: id,
    };

    studyTimeApi.submit(query);
    scoreApi.submit(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aggregation, to]);

  const studyTimes = StatisticResource.listStatistic(
    from,
    to,
    Statistic.EikenStudyTimes,
    aggregation as Aggregation,
    id
  );

  const scores = StatisticResource.listStatistic(
    from,
    to,
    Statistic.EikenScores,
    aggregation as Aggregation,
    id
  );

  const xAxisFormatter = (v: any, context: any) => {
    if (context.location === 'tick') {
      return aggregation === Aggregation.Month
        ? moment(v).format("MMM 'YY")
        : moment(v).format('MMM D');
    }

    return aggregation === Aggregation.Month
      ? moment(v).format('MMM YYYY')
      : moment(v).format('[Week of] MMM D, YYYY');
  };

  const fetchStudent = useRequester<StudentResource>({
    onSubmit: (id) => StudentResource.fetchOne(id),
    onError: console.log,
  });

  useEffect(() => {
    fetchStudent.submit(id);
  }, [id]);

  if (!id) return <Alert severity="error">No student ID provided</Alert>;
  const student = StudentResource.get(id).data as StudentData;
  if (!student) {
    return (
      <Box className="flex flex-col items-center h-full justify-center">
        {fetchStudent.error ? (
          <Alert severity="error">{fetchStudent.error}</Alert>
        ) : (
          <CircularProgress />
        )}
      </Box>
    );
  }

  return (
    <Page>
      <Page.Header>
        <Box className="flex flex-col h-full">
          <Button
            className="flex flex-row items-center gap-2 max-w-fit"
            onClick={() => navigate(-1)}
            sx={{ mt: 1 }}
          >
            <ArrowBack fontSize="small" /> Back
          </Button>

          <Box className="flex flex-row items-center gap-4" sx={{ mt: 3 }}>
            <Box className="flex flex-col items-center gap-2">
              <Avatar src={student.avatar} sx={{ width: 80, height: 80 }} />
            </Box>
            <Box className="flex flex-col h-full">
              <Typography variant="body1" color="text.secondary">
                Students / Student Profile
              </Typography>
              <Typography variant="h1">
                {student.firstName} {student.lastName}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Page.Header>
      <Page.Content>
        <Box sx={{ mt: 5 }} className="grid grid-cols-2 gap-6">
          <Card>
            <Box className="flex flex-row justify-between items-center ml-3 mr-4">
              <Typography variant="h3">Study Time</Typography>

              <Typography variant="body2" color="text.secondary">
                Average:{' '}
                {Math.round(
                  studyTimes.reduce((acc, item) => acc + item.data.sum, 0) /
                    studyTimes.length /
                    60
                )}{' '}
                mins
              </Typography>
            </Box>

            <Box className="flex flex-row justify-between items-center mt-6">
              <Box className="ml-3 flex items-center gap-3 mb-4 w-full">
                <Typography variant="body2" color="text.secondary">
                  View by
                </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={aggregation}
                  exclusive
                  onChange={(event, value) => setAggregation(value)}
                  aria-label="Platform"
                >
                  <ToggleButton value={Aggregation.Week}>Week</ToggleButton>
                  <ToggleButton value={Aggregation.Month}>Month</ToggleButton>
                </ToggleButtonGroup>
                <Box className="flex-grow"></Box>
                <Box sx={{ mr: 1 }}>
                  <DateRange
                    to={to}
                    aggregation={aggregation}
                    onChange={setTo}
                  />
                </Box>
              </Box>
            </Box>
            <LineChart
              loading={studyTimeApi.loading}
              dataset={studyTimes.map((item) => ({
                x: new Date(item.data.startDate).getTime(),
                y: Math.round(item.data.sum / 60),
              }))}
              series={[{ color: plColors.primary[2] }]}
              xAxisFormatter={xAxisFormatter}
              margin={{ left: 12, right: 52, top: 16, bottom: 30 }}
            />
          </Card>
        </Box>

        <Box className="flex flex-col gap-6" sx={{ mt: 5 }}>
          <Typography variant="h2">Questions</Typography>
          <Box className="grid grid-cols-6 gap-6">
            {['fulltest', 'pronunciation', 'q1', 'q2', 'q3', 'q4'].map(
              (section) => (
                <ChatCard
                  key={section}
                  chat={student.latestChat}
                  section={section}
                  compact
                />
              )
            )}
          </Box>
        </Box>

        <Box sx={{ mt: 5 }} className="grid grid-cols-2 gap-6">
          <Card>
            <Box className="flex flex-row justify-between items-center ml-3 mr-4">
              <Typography variant="h3">Scores</Typography>

              <Typography variant="body2" color="text.secondary">
                Average:{' '}
                {Math.round(
                  scores.reduce((acc, item) => acc + item.data.avg, 0) /
                    scores.length
                )}
              </Typography>
            </Box>

            <Box className="flex flex-row justify-between items-center mt-6">
              <Box className="ml-3 flex items-center gap-3 mb-4 w-full">
                <Typography variant="body2" color="text.secondary">
                  View by
                </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={aggregation}
                  exclusive
                  onChange={(event, value) => setAggregation(value)}
                  aria-label="Platform"
                >
                  <ToggleButton value={Aggregation.Week}>Week</ToggleButton>
                  <ToggleButton value={Aggregation.Month}>Month</ToggleButton>
                </ToggleButtonGroup>
                <Box className="flex-grow"></Box>
                <Box sx={{ mr: 1 }}>
                  <DateRange
                    to={to}
                    aggregation={aggregation}
                    onChange={setTo}
                  />
                </Box>
              </Box>
            </Box>
            <LineChart
              loading={scoreApi.loading}
              dataset={scores.map((item) => ({
                x: new Date(item.data.startDate).getTime(),
                y: item.data.avg,
              }))}
              series={[{ color: plColors.primary[2] }]}
              xAxisFormatter={xAxisFormatter}
              margin={{ left: 12, right: 52, top: 16, bottom: 30 }}
            />
          </Card>
          <Box>
            <Box className="flex flex-row justify-between items-center">
              <Typography variant="h2">Latest Practices</Typography>
              <Button
                variant="text"
                color="primary"
                onClick={() => navigate(`/chats?userId=${id}`)}
              >
                View all
              </Button>
            </Box>
            <Box className="flex flex-col gap-3 mt-4">
              {latestChatsApi.loading ? (
                <CircularProgress size={20} />
              ) : (
                ChatResource.listLatestChatsForUser(id).map((chat) => (
                  <ChatCard
                    chat={chat.data}
                    key={chat.data.id}
                    onClick={() => navigate(`/chats/${chat.data.id}`)}
                  />
                ))
              )}
            </Box>
          </Box>
        </Box>
      </Page.Content>
    </Page>
  );
}
