import BaseResource from './BaseResource';
import moment from 'moment-timezone';

export interface StatisticData {
  id: string;
  startDate: string;
  avg: number;
  count: number;
  sum: number;
}

export enum Statistic {
  EikenScores = 'eikenscores',
  EikenStudyTimes = 'eikenstudytimes',
}

export enum Aggregation {
  Week = 'week',
  Month = 'month',
}

// id is the backend id for each timeseries entry. It has the following format:
// {statistic}:{year}:{aggregation}:{value}:user:{user}
// e.g.
// eikenscores:2024:month:12:user:*
// eikenscores:2024:week:1:user:67c50a1f72695c4ed5f2226e
function buildIds(
  from: Date,
  to: Date,
  statistic: Statistic,
  aggregation: Aggregation,
  user = '*'
) {
  const startDate = moment(from).startOf(aggregation);
  const endDate = moment(to).startOf(aggregation).add(1, aggregation);
  
  const ids = [];

  for (let date = startDate; date <= endDate; date.add(1, aggregation)) {
    const isoWeek = date.isoWeek();
    const month = date.month() + 1;
    const year = date.year();
    const isoWeekYear = date.isoWeekYear();

    if (aggregation === Aggregation.Week) {
      ids.push(`${statistic}:${isoWeekYear}:week:${isoWeek}:user:${user}`);
    } else {
      ids.push(`${statistic}:${year}:month:${month}:user:${user}`);
    }
  }

  return ids;
}

function calculateDateFirstDayOfWeek(year: string, week: string): moment.Moment {
  return moment()
    .isoWeek(Number(week))
    .isoWeekYear(Number(year))
    .startOf('isoWeek')
    .tz('Asia/Tokyo', true);
}

function calculateDateMonth(year: string, month: string): moment.Moment {
  return moment()
    .month(Number(month) - 1)
    .isoWeekYear(Number(year))
    .startOf('month')
    .tz('Asia/Tokyo', true);
}

// getEmptyDataFromId is used to get the empty data for a given id.
// It is used to fill in the data for the ids that do not exist in the backend.
function getEmptyDataFromId(id: string) {
  const [, year, aggregation, value] = id.split(':');

  const startDate =
    aggregation === Aggregation.Week
      ? calculateDateFirstDayOfWeek(year, value)
      : calculateDateMonth(year, value);

  return {
    id,
    startDate: startDate.toISOString(),
    avg: 0,
    count: 0,
    sum: 0,
  };
}

function parseId(id: string) {
  const [statistic, year, aggregation, value, , user] = id.split(':');

  const startDate =
    aggregation === Aggregation.Week
      ? moment.utc(`${year}-01-01`).week(Number(value)).startOf('isoWeek')
      : moment.utc(`${year}-01-01`).month(Number(value) - 1);

  const endDate =
    aggregation === Aggregation.Week
      ? moment.utc(`${year}-01-01`).week(Number(value)).endOf('isoWeek')
      : moment
          .utc(`${year}-01-01`)
          .month(Number(value) - 1)
          .endOf('month');

  return { statistic, year, aggregation, user, startDate, endDate, value };
}

class StatisticResource extends BaseResource<StatisticData> {
  static get storeName(): string {
    return 'StatisticResource';
  }

  static get endpoint(): string {
    return `/${this.scope}/statistics/timeseries`;
  }

  static buildIds(
    from: Date,
    to: Date,
    statistic: Statistic,
    aggregation: Aggregation,
    user = '*'
  ) {
    return buildIds(from, to, statistic, aggregation, user);
  }

  static parseId(id: string) {
    return parseId(id);
  }

  static async fetchStatistic(
    statistic: Statistic,
    q: any
  ): Promise<StatisticResource[]> {
    const raw: any = await this.http.get(`${this.endpoint}/${statistic}`, q);
    return this.upsertManyAndReturn(raw);
  }

  static listStatistic(
    from: Date,
    to: Date,
    statistic: Statistic,
    aggregation: Aggregation,
    user = '*'
  ): StatisticResource[] {
    const ids = buildIds(from, to, statistic, aggregation, user);

    const data = ids.map((id: string) => {
      const obj = this.store.state[this.storeName][id];
      return obj ? obj : getEmptyDataFromId(id);
    });

    return data.map((item: any) => new this(item));
  }

  static toObject(raw: any): StatisticResource {
    return new StatisticResource(raw as StatisticData);
  }
}

export default StatisticResource;
