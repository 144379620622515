import {
  styled,
  Toolbar,
  IconButton,
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Box,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import AvatarMenu from './AvatarMenu';

const drawerWidth = 195;

interface CustomAppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const CustomAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<CustomAppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

type Props = {
  open: boolean;
  handleDrawerOpen: () => void;
};

export default function Appbar({ open, handleDrawerOpen }: Props) {
  return (
    <CustomAppBar position="fixed" open={open}>
      <Toolbar>
        <IconButton
          color="primary"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,

            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box className="flex flex-grow"></Box>
        <AvatarMenu />
      </Toolbar>
    </CustomAppBar>
  );
}
