import moment from 'moment';

export function fromNow(date: Date | string) {
  if (!date) return 'not scheduled';

  return moment(date).fromNow();
}

// Example: Today, 11:34am
// Example: Yesterday, 11:34am
// Example: 2025-03-26, 11:34am
export function formatDate(date: string) {
  const today = new Date();
  const dateObj = new Date(date);
  const isToday = moment(dateObj).isSame(today, 'day');
  const isYesterday = moment(dateObj).isSame(
    today.getTime() - 24 * 60 * 60 * 1000,
    'day'
  );

  if (isToday) {
    return `Today, ${moment(dateObj).format('h:mm a')}`;
  } else if (isYesterday) {
    return `Yesterday, ${moment(dateObj).format('h:mm a')}`;
  } else {
    return moment(dateObj).format('MMM D, YYYY, h:mm a');
  }
}

export function formatTime(value: number): string {
  const duration = moment.duration(value, 'seconds');
  const minutesText = duration.minutes() > 0 ? `${duration.minutes()}m ` : '';
  const secondsText = duration.seconds() > 0 ? `${duration.seconds()}s` : '';
  return `${minutesText}${secondsText}`.trim();
};