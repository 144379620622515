import moment from 'moment';
import { Aggregation } from '../../resources/StatisticResource';
import { Box, IconButton, Typography } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

interface DateRangeProps {
  aggregation: Aggregation;
  to: Date;
  onChange: (date: Date) => void;
}

export default function DateRange({
  aggregation,
  to: to_,
  onChange,
}: DateRangeProps) {
  let from: moment.Moment;
  let to: moment.Moment;
  let handleClickLeft;
  let handleClickRight;

  if (aggregation === Aggregation.Week) {
    to = moment(to_).endOf('isoWeek');
    from = moment(to).subtract(10, 'weeks').startOf('isoWeek');
    handleClickLeft = () => onChange(to.subtract(12, 'weeks').toDate());
    handleClickRight = () => onChange(to.add(12, 'weeks').toDate());
  } else {
    to = moment(to_).endOf('month');
    from = moment(to).subtract(10, 'months').startOf('month');
    handleClickLeft = () => onChange(to.subtract(12, 'months').toDate());
    handleClickRight = () => onChange(to.add(12, 'months').toDate());
  }

  return (
    <Box className="flex flex-row items-center gap-2">
      <IconButton onClick={handleClickLeft}>
        <ChevronLeft sx={{ fontSize: 16 }} />
      </IconButton>
      <Typography>
        {from.format('DD/MM/YYYY')} - {to.format('DD/MM/YYYY')}
      </Typography>
      <IconButton onClick={handleClickRight}>
        <ChevronRight sx={{ fontSize: 16 }} />
      </IconButton>
    </Box>
  );
}
