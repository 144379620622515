import BaseResource from './BaseResource';

export interface CategoryData {
  name: string;
  nameJp: string;
  description: string;
  descriptionJp: string;
  image: string;
  items: string[];
}

export interface LocationData {
  id: string;
  name: string;
  nameJp: string;
  description: string;
  descriptionJp: string;
  position: {
    longitude: number;
    latitude: number;
  };
  image: string;
  flag: string;
  categories: CategoryData[];
  tags: string[];
  active: boolean;
}

class LocationResource extends BaseResource<LocationData> {
  static get storeName(): string {
    return 'LocationResource';
  }

  static get endpoint(): string {
    return `/${this.scope}/locations`;
  }

  static async addCategory(locationId: string, data: any): Promise<void> {
    await this.http.post(`${this.endpoint}/${locationId}/categories`, {}, data);
    await this.fetchOne(locationId);
  }

  static async updateCategory(
    locationId: string,
    categoryName: string,
    data: any
  ): Promise<void> {
    await this.http.patch(
      `${this.endpoint}/${locationId}/categories/${categoryName}`,
      {},
      data
    );
    await this.fetchOne(locationId);
  }

  static async deleteCategory(
    locationId: string,
    categoryName: string
  ): Promise<void> {
    await this.http.delete(
      `${this.endpoint}/${locationId}/categories/${categoryName}`
    );
    await this.fetchOne(locationId);
  }

  static toObject(raw: any): LocationResource {
    return new LocationResource(raw as LocationData);
  }
}

export default LocationResource;
