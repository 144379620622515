import { Search } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import * as _ from 'lodash';
import { useMemo } from 'react';

type Props = {
  placeholder?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
};

export default function Searchbar({
  onChange,
  placeholder = 'Search',
  disabled,
}: Props) {
  const debouncedOnChange = useMemo(() => {
    if (!onChange) return undefined;

    return _.debounce(onChange, 500);
  }, [onChange]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedOnChange?.(event.target.value);
  };

  return (
    <TextField
      disabled={disabled}
      sx={{
        fontSize: '14px',
        fontWeight: 400,
        backgroundColor: 'background.1',
        borderRadius: '8px',
        '& .MuiOutlinedInput-notchedOutline': {
          border: '1px solid',
          borderColor: 'border.1',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
          {
            borderColor: 'border.primary',
          },
      }}
      placeholder={placeholder}
      variant="outlined"
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search fontSize="small" />
          </InputAdornment>
        ),
      }}
    />
  );
}
