import { Typography } from '@mui/material';

interface Props {
  type?: string;
}

export default function PassFail({ type }: Props) {
  if (type === 'pass') {
    return (
      <Typography
        variant="h6"
        color="text.success"
        sx={{
          backgroundColor: 'success.main',
          borderRadius: 4,
          border: '1px solid',
          borderColor: 'border.success',
          px: 1.5,
          py: 0.5,
          width: 'fit-content',
        }}
      >
        Pass
      </Typography>
    );
  }

  if (type === 'fail') {
    return (
      <Typography
        variant="h6"
        color="text.warning"
        sx={{
          backgroundColor: 'warning.main',
          borderRadius: 4,
          border: '1px solid',
          borderColor: 'border.warning',
          px: 1.5,
          py: 0.5,
          width: 'fit-content',
        }}
      >
        Fail
      </Typography>
    );
  }

  return (
    <Typography
      variant="h6"
      color="text.secondary"
      sx={{
        backgroundColor: 'border.1',
        borderRadius: 4,
        border: '1px solid',
        borderColor: 'text.secondary',
        px: 1.5,
        py: 0.5,
        width: 'fit-content',
      }}
    >
      N/A
    </Typography>
  );
}
