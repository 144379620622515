/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useRequester } from '../../hooks';
import { Page } from '../../design';
import {
  EikenMaterialData,
  EikenMaterialResource,
  StudentData,
  StudentResource,
} from '../../resources';
import { useEffect } from 'react';
import ChatResource, { ChatData } from '../../resources/ChatResource';
import { formatDate } from '../../utils';
import FullTestTab from './FullTestTab';
import PronunciationTab from './PronunciationTab';
import QuestionTab from './QuestionTab';
import { ArrowBack } from '@mui/icons-material';

const testTypeLabels = {
  fulltest: 'Full Test',
  pronunciation: 'Pronunciation',
  q1: 'Q1',
  q2: 'Q2',
  q3: 'Q3',
  q4: 'Q4',
};

export default function ChatPage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [params, setParams] = useSearchParams();

  const studentApi = useRequester<StudentResource>({
    onSubmit: (id) => StudentResource.fetchOne(id),
    onError: console.log,
  });

  const eikenMaterialApi = useRequester<EikenMaterialResource>({
    onSubmit: (id) => EikenMaterialResource.fetchOne(id),
    onError: console.log,
  });

  const chatApi = useRequester<ChatResource>({
    onSubmit: (id) => ChatResource.fetchOne(id),
    onSuccess: (chat) => {
      studentApi.submit(chat.data.userId);
      eikenMaterialApi.submit(chat.data.meta.eikenMaterialId);
    },
    onError: console.log,
  });

  useEffect(() => {
    chatApi.submit(id);
  }, [id]);

  if (!id) return <Alert severity="error">No chat ID provided</Alert>;
  const chat = ChatResource.get(id).data as ChatData;
  const eikenMaterial = EikenMaterialResource.get(chat?.meta?.eikenMaterialId)
    .data as EikenMaterialData;

  if (!chat || !eikenMaterial) {
    return (
      <Box className="flex flex-col items-center h-full justify-center">
        {chatApi.error ? (
          <Alert severity="error">{chatApi.error}</Alert>
        ) : (
          <CircularProgress />
        )}
      </Box>
    );
  }

  const student = StudentResource.get(chat.userId).data as StudentData;

  if (!student) {
    return (
      <Box className="flex flex-col items-center h-full justify-center">
        {studentApi.error ? (
          <Alert severity="error">{studentApi.error}</Alert>
        ) : (
          <CircularProgress />
        )}
      </Box>
    );
  }

  const availableTabs = Object.keys(testTypeLabels).filter(
    (key) =>
      chat.meta?.eikenTestType === 'fulltest' ||
      chat.meta?.eikenTestType === key
  );

  const tab = params.get('tab') || availableTabs[0];

  return (
    <Page>
      <Page.Header>
        <Box className="flex flex-col h-full">
          <Button
            className="flex flex-row items-center gap-2 max-w-fit"
            onClick={() => navigate(-1)}
            sx={{ mt: 1 }}
          >
            <ArrowBack fontSize="small" /> Back
          </Button>
          <Box className="flex flex-row items-center gap-4" sx={{ mt: 3 }}>
            <Box className="flex flex-col items-center gap-2">
              <Avatar src={student.avatar} sx={{ width: 80, height: 80 }} />
            </Box>
            <Box className="flex flex-col h-full">
              <Typography variant="body1" color="text.secondary">
                Students / {student.firstName} {student.lastName} /{' '}
                {formatDate(chat.createdAt)}
              </Typography>
              <Typography variant="h1">
                {
                  testTypeLabels[
                    chat.meta?.eikenTestType as keyof typeof testTypeLabels
                  ]
                }
                {chat.meta?.eikenTestType === 'fulltest' &&
                  tab !== 'fulltest' && (
                    <> / {testTypeLabels[tab as keyof typeof testTypeLabels]}</>
                  )}
              </Typography>
            </Box>
          </Box>
          <Box className="flex-grow"></Box>
          <Box>
            <Tabs
              value={tab}
              onChange={(_, value) => setParams({ tab: value })}
            >
              {availableTabs.map((key) => (
                <Tab
                  key={key}
                  label={testTypeLabels[key as keyof typeof testTypeLabels]}
                  value={key}
                />
              ))}
            </Tabs>
          </Box>
        </Box>
      </Page.Header>
      <Page.Content>
        <Box sx={{ mt: 5 }}>
          {tab === 'fulltest' && <FullTestTab chat={chat} />}
          {tab === 'pronunciation' && (
            <PronunciationTab
              chat={chat}
              referenceText={eikenMaterial.referenceText}
            />
          )}
          {['q1', 'q2', 'q3', 'q4'].includes(tab) && (
            <QuestionTab type={tab} chat={chat} />
          )}
          <Box className="flex flex-col gap-4 mt-8">
            <Typography variant="h2">Reviews</Typography>
            {chat.lines
              .filter(
                (line) => tab === 'fulltest' || tab === line.meta?.section
              )
              .map((line) => (
                <Card key={line.text} sx={{ p: 3, borderRadius: 3 }}>
                  <Box className="flex flex-row items-start gap-2">
                    <Box
                      className="flex flex-row items-center gap-4"
                      sx={{ width: '160px' }}
                    >
                      {line.isUser ? (
                        <>
                          <Avatar
                            src={student.avatar}
                            sx={{ width: 40, height: 40 }}
                          />
                          <Box className="flex flex-col">
                            <Typography variant="h6">
                              {student.firstName} {student.lastName}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              You
                            </Typography>
                          </Box>
                        </>
                      ) : (
                        <>
                          <Avatar
                            src={chat.tutor.avatar}
                            sx={{ width: 40, height: 40 }}
                          />
                          <Box className="flex flex-col">
                            <Typography variant="h6">
                              {chat.tutor.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              AI Tutor
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Box>

                    <Typography
                      variant="body1"
                      sx={{
                        flex: 0,
                        flexGrow: 1,
                      }}
                      textAlign="justify"
                      whiteSpace="break-spaces"
                    >
                      {line.text}
                    </Typography>
                  </Box>
                </Card>
              ))}
          </Box>
        </Box>
      </Page.Content>
    </Page>
  );
}
