import { lineElementClasses, LineChart as MuiLineChart } from '@mui/x-charts';
import { plColors } from '../..';

interface LineChartProps {
  dataset: {
    x: number;
    y: number;
  }[];
  series: {
    color: string;
  }[];
  loading?: boolean;
  xAxisFormatter?: (value: number, context: any) => string;
  yAxisFormatter?: (value: number, context: any) => string;
  margin?: {
    left: number;
    right: number;
    top: number;
    bottom: number;
  };
}

export default function LineChart({
  dataset,
  series,
  loading,
  xAxisFormatter,
  yAxisFormatter,
  margin = { left: 30, right: 30, top: 30, bottom: 30 },
}: LineChartProps) {
  const dataSeries = series.map((s, index) => ({
    id: index,
    dataKey: 'y',
    area: true,
    curve: 'linear',
    color: s.color,
  }));

  const sx: any = {
    [`& .${lineElementClasses.root}`]: {
      strokeWidth: 0.5,
      stroke: dataSeries[0]?.color,
    },
  };

  dataSeries.forEach((s) => {
    sx[`& .MuiAreaElement-series-${s.id}`] = {
      fill: `url('#LineChartGradient-${s.color}')`,
    };
  });

  const allZeroes = dataset.every((d) => d.y === 0);

  return (
    <MuiLineChart
      loading={loading}
      sx={sx}
      dataset={allZeroes ? [] : (dataset as any)}
      xAxis={[
        {
          dataKey: 'x',
          valueFormatter: xAxisFormatter,
          scaleType: 'point',
        },
      ]}
      yAxis={[
        {
          dataKey: 'y',
          valueFormatter: yAxisFormatter,
        },
      ]}
      series={dataSeries as any}
      height={300}
      margin={margin}
      grid={{ vertical: true, horizontal: true }}
      rightAxis={{
        disableLine: true,
        disableTicks: true,
        tickLabelStyle: {
          textAnchor: 'start',
          fontSize: 14,
          fontWeight: 500,
          fill: plColors.text.primary,
        },
      }}
      bottomAxis={{
        disableLine: true,
        disableTicks: true,
        tickLabelStyle: {
          fontSize: 12,
          fontWeight: 400,
          fill: plColors.text[4],
        },
      }}
      leftAxis={null}
    >
      <defs>
        {series.map((s) => (
          <linearGradient
            key={`LineChartGradient-${s.color}`}
            id={`LineChartGradient-${s.color}`}
            gradientTransform="rotate(90)"
          >
            <stop offset="10%" stopColor={`${s.color}cc`} />
            <stop offset="90%" stopColor={`${s.color}11`} />
          </linearGradient>
        ))}
      </defs>
    </MuiLineChart>
  );
}
