import {
  Box,
  Card,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts';
import { LineChart, Page, DateRange } from '../../design';
import { plColors } from '../..';
import { useRequester } from '../../hooks';
import { StatisticResource } from '../../resources';
import { Aggregation, Statistic } from '../../resources/StatisticResource';
import moment from 'moment';

function DashboardPage() {
  const { user } = useContext(AuthContext);
  const [aggregation, setAggregation] = useState<Aggregation>(Aggregation.Week);
  const [to, setTo] = useState<Date>(new Date());

  const from =
    aggregation === Aggregation.Week
      ? moment(to).subtract(12, 'weeks').toDate()
      : moment(to).subtract(12, 'months').toDate();

  const scoreApi = useRequester<StatisticResource[]>({
    onSubmit: (q) => StatisticResource.fetchStatistic(Statistic.EikenScores, q),
    onError: console.log,
  });

  const studyTimeApi = useRequester<StatisticResource[]>({
    onSubmit: (q) =>
      StatisticResource.fetchStatistic(Statistic.EikenStudyTimes, q),
    onError: console.log,
  });

  useEffect(() => {
    const query = {
      aggregation,
      from,
      to,
    };

    scoreApi.submit(query);
    studyTimeApi.submit(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aggregation, to]);

  const scores = StatisticResource.listStatistic(
    from,
    to,
    Statistic.EikenScores,
    aggregation
  );

  const studyTimes = StatisticResource.listStatistic(
    from,
    to,
    Statistic.EikenStudyTimes,
    aggregation as Aggregation
  );

  const xAxisFormatter = (v: any, context: any) => {
    if (context.location === 'tick') {
      return aggregation === Aggregation.Month
        ? moment(v).format("MMM 'YY")
        : moment(v).format('MMM D');
    }

    return aggregation === Aggregation.Month
      ? moment(v).format('MMM YYYY')
      : moment(v).format('[Week of] MMM D, YYYY');
  };

  return (
    <Page>
      <Page.Header>
        <Box className="flex flex-col h-full">
          <Typography variant="body1" color="text.secondary" sx={{ mt: 8 }}>
            👋 Hey, {user?.data.firstName}!
          </Typography>
          <Typography variant="h1">
            Welcome to your student dashboard
          </Typography>
          <Box className="flex-grow"></Box>
          <Box>
            <Tabs value="all">
              <Tab label="All Students" value="all" />
            </Tabs>
          </Box>
        </Box>
      </Page.Header>
      <Page.Content>
        <Box sx={{ mt: 5 }}>
          <Card
            sx={{
              // Reducing left-padding to fix a weirdness with the chart
              pl: 1,
            }}
          >
            <Box className="ml-6 flex items-center gap-3">
              <Typography variant="body2" color="text.secondary">
                View by
              </Typography>
              <ToggleButtonGroup
                color="primary"
                value={aggregation}
                exclusive
                onChange={(event, value) => setAggregation(value)}
                aria-label="Platform"
              >
                <ToggleButton value={Aggregation.Week}>Week</ToggleButton>
                <ToggleButton value={Aggregation.Month}>Month</ToggleButton>
              </ToggleButtonGroup>
              <Box className="flex-grow"></Box>
              <Box sx={{ mr: 2 }}>
                <DateRange to={to} aggregation={aggregation} onChange={setTo} />
              </Box>
            </Box>
            <Box className="grid grid-cols-2 gap-6 mt-6">
              <Box>
                <Box
                  className="flex flex-row justify-between items-center"
                  sx={{ ml: 3, mr: 2 }}
                >
                  <Typography variant="h3">Scores</Typography>
                  <Typography variant="body2" color="text.secondary">
                    Average:{' '}
                    {(
                      scores.reduce((acc, item) => acc + item.data.avg, 0) /
                      scores.length
                    ).toFixed(2)}
                  </Typography>
                </Box>

                <LineChart
                  loading={scoreApi.loading}
                  dataset={scores.map((item) => ({
                    x: new Date(item.data.startDate).getTime(),
                    y: item.data.sum,
                  }))}
                  series={[{ color: plColors.primary[2] }]}
                  xAxisFormatter={xAxisFormatter}
                  margin={{ left: 28, right: 36, top: 16, bottom: 30 }}
                />
              </Box>
              <Box>
                <Box
                  className="flex flex-row justify-between items-center"
                  sx={{ ml: 4, mr: 2 }}
                >
                  <Typography variant="h3">Study Time</Typography>
                  <Typography variant="body2" color="text.secondary">
                    Average:{' '}
                    {Math.round(
                      studyTimes.reduce((acc, item) => acc + item.data.sum, 0) /
                        studyTimes.length / 60
                    )}{' '}
                    mins
                  </Typography>
                </Box>
                <LineChart
                  loading={studyTimeApi.loading}
                  dataset={studyTimes.map((item) => ({
                    x: new Date(item.data.startDate).getTime(),
                    y: Math.round(item.data.sum/60),
                  }))}
                  series={[{ color: plColors.primary[2] }]}
                  xAxisFormatter={xAxisFormatter}
                  margin={{ left: 36, right: 56, top: 16, bottom: 30 }}
                />
              </Box>
            </Box>
          </Card>
        </Box>
      </Page.Content>
    </Page>
  );
}

export default DashboardPage;
