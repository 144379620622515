import './App.css';
import {
  MonitorHeart,
  Newspaper as NewsIcon,
  Person2 as PersonIcon,
  Source as SourceIcon,
  FontDownload as FontDownloadIcon,
  LocationOn as LocationIcon,
} from '@mui/icons-material';
import {
  PiChartDonutFill,
  PiQuestionMarkFill,
  PiStudentFill,
  PiTagFill,
  PiChatsFill,
} from 'react-icons/pi';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ReactNode, useContext } from 'react';
import cookie from 'js-cookie';
import Layout from './layout/Layout';
import {
  ActivitiesPage,
  ArticleEditPage,
  ArticlePage,
  ArticlesPage,
  DictionaryPage,
  DashboardPage,
  LoginPage,
  MediaEditPage,
  MediaPage,
  TutorEditPage,
  TutorPage,
  TutorsPage,
  UsersPage,
  TagsPage,
  LocationsPage,
  LocationEditPage,
  LocationPage,
  FeedbackLevelsPage,
  FeedbackLevelEditPage,
  EikenMaterialsPage,
  EikenMaterialEditPage,
  EikenMaterialPage,
  StudentsPage,
  StudentPage,
  ChatsPage,
  ChatPage,
} from './pages';
import { AuthContext } from './contexts';
import { BaseResource } from './resources';
import { AppScope, UserRole } from './resources/BaseResource';

interface IRoute {
  title?: string;
  path: string;
  icon?: ReactNode;
  Component: () => JSX.Element;
  canAccess?: (appScope: AppScope, userRole: UserRole) => boolean;
}

const routes: IRoute[] = [
  {
    title: 'Dashboard',
    icon: <PiChartDonutFill />,
    path: '/',
    Component: DashboardPage,
    canAccess: (appScope, userRole) =>
      [AppScope.VR].includes(appScope) &&
      [UserRole.Admin].includes(userRole),
  },
  {
    title: 'Activities',
    path: '/activities',
    icon: <MonitorHeart />,
    Component: ActivitiesPage,
    canAccess: (appScope, userRole) =>
      [AppScope.Lite, AppScope.LiteBeginner].includes(appScope) &&
      [UserRole.Admin].includes(userRole),
  },
  {
    path: '/articles/new',
    Component: ArticleEditPage,
    canAccess: (appScope, userRole) =>
      [AppScope.Lite, AppScope.LiteBeginner].includes(appScope) &&
      [UserRole.Admin].includes(userRole),
  },
  {
    path: '/articles/:id',
    Component: ArticlePage,
    canAccess: (appScope, userRole) =>
      [AppScope.Lite, AppScope.LiteBeginner].includes(appScope) &&
      [UserRole.Admin].includes(userRole),
  },
  {
    path: '/articles/:id/edit',
    Component: ArticleEditPage,
    canAccess: (appScope, userRole) =>
      [AppScope.Lite, AppScope.LiteBeginner].includes(appScope) &&
      [UserRole.Admin].includes(userRole),
  },
  {
    title: 'Articles',
    path: '/articles',
    icon: <NewsIcon />,
    Component: ArticlesPage,
    canAccess: (appScope, userRole) =>
      [AppScope.Lite, AppScope.LiteBeginner].includes(appScope) &&
      [UserRole.Admin].includes(userRole),
  },
  {
    path: '/media/new',
    Component: MediaEditPage,
    canAccess: (appScope, userRole) =>
      [AppScope.Lite, AppScope.LiteBeginner].includes(appScope) &&
      [UserRole.Admin].includes(userRole),
  },
  {
    path: '/media/:id/edit',
    Component: MediaEditPage,
    canAccess: (appScope, userRole) =>
      [AppScope.Lite, AppScope.LiteBeginner].includes(appScope) &&
      [UserRole.Admin].includes(userRole),
  },
  {
    title: 'Media',
    path: '/media',
    icon: <SourceIcon />,
    Component: MediaPage,
    canAccess: (appScope, userRole) =>
      [AppScope.Lite, AppScope.LiteBeginner].includes(appScope) &&
      [UserRole.Admin].includes(userRole),
  },
  {
    title: 'Tutors',
    path: '/tutors',
    icon: (
      <path d="M20 17C20.5304 17 21.0391 16.7893 21.4142 16.4142C21.7893 16.0391 22 15.5304 22 15V4C22 3.46957 21.7893 2.96086 21.4142 2.58579C21.0391 2.21071 20.5304 2 20 2H9.46C9.81 2.61 10 3.3 10 4H20V15H11V17M15 7V9H9V22H7V16H5V22H3V14H1.5V9C1.5 8.46957 1.71071 7.96086 2.08579 7.58579C2.46086 7.21071 2.96957 7 3.5 7H15ZM8 4C8 4.53043 7.78929 5.03914 7.41421 5.41421C7.03914 5.78929 6.53043 6 6 6C5.46957 6 4.96086 5.78929 4.58579 5.41421C4.21071 5.03914 4 4.53043 4 4C4 3.46957 4.21071 2.96086 4.58579 2.58579C4.96086 2.21071 5.46957 2 6 2C6.53043 2 7.03914 2.21071 7.41421 2.58579C7.78929 2.96086 8 3.46957 8 4Z" />
    ),
    Component: TutorsPage,
    canAccess: (appScope, userRole) =>
      [AppScope.VR, AppScope.Lite, AppScope.LiteBeginner].includes(appScope) &&
      [UserRole.Admin].includes(userRole),
  },
  {
    title: 'Dictionary',
    path: '/dictionary',
    icon: <FontDownloadIcon />,
    Component: DictionaryPage,
    canAccess: (appScope, userRole) =>
      [AppScope.Lite, AppScope.LiteBeginner].includes(appScope) &&
      [UserRole.Admin].includes(userRole),
  },
  {
    title: 'Users',
    path: '/users',
    icon: <PersonIcon />,
    Component: UsersPage,
    canAccess: (appScope, userRole) =>
      [AppScope.VR, AppScope.Lite, AppScope.LiteBeginner].includes(appScope) &&
      [UserRole.Admin].includes(userRole),
  },
  {
    title: 'Students',
    path: '/students',
    icon: <PiStudentFill />,
    Component: StudentsPage,
    canAccess: (appScope, userRole) =>
      [AppScope.VR, AppScope.Lite, AppScope.LiteBeginner].includes(appScope) &&
      [UserRole.Admin, UserRole.Teacher].includes(userRole),
  },
  {
    path: '/students/:id',
    Component: StudentPage,
    canAccess: (appScope, userRole) =>
      [AppScope.VR, AppScope.Lite, AppScope.LiteBeginner].includes(appScope) &&
      [UserRole.Admin, UserRole.Teacher].includes(userRole),
  },
  {
    path: '/tutors/new',
    Component: TutorEditPage,
    canAccess: (appScope, userRole) =>
      [AppScope.VR, AppScope.Lite, AppScope.LiteBeginner].includes(appScope) &&
      [UserRole.Admin].includes(userRole),
  },
  {
    path: '/tutors/:id/edit',
    Component: TutorEditPage,
    canAccess: (appScope, userRole) =>
      [AppScope.VR, AppScope.Lite, AppScope.LiteBeginner].includes(appScope) &&
      [UserRole.Admin].includes(userRole),
  },
  {
    path: '/tutors/:id',
    Component: TutorPage,
    canAccess: (appScope, userRole) =>
      [AppScope.VR, AppScope.Lite, AppScope.LiteBeginner].includes(appScope) &&
      [UserRole.Admin].includes(userRole),
  },
  {
    title: 'Tags',
    icon: <PiTagFill />,
    path: '/tags',
    Component: TagsPage,
    canAccess: (appScope, userRole) =>
      [AppScope.VR, AppScope.Lite, AppScope.LiteBeginner].includes(appScope) &&
      [UserRole.Admin].includes(userRole),
  },
  {
    path: '/eiken-materials/new',
    Component: EikenMaterialEditPage,
    canAccess: (appScope, userRole) =>
      [AppScope.VR].includes(appScope) && [UserRole.Admin].includes(userRole),
  },
  {
    path: '/eiken-materials/:id/edit',
    Component: EikenMaterialEditPage,
    canAccess: (appScope, userRole) =>
      [AppScope.VR].includes(appScope) && [UserRole.Admin].includes(userRole),
  },
  {
    path: '/eiken-materials/:id',
    Component: EikenMaterialPage,
    canAccess: (appScope, userRole) =>
      [AppScope.VR].includes(appScope) && [UserRole.Admin].includes(userRole),
  },
  {
    title: 'Eiken Materials',
    icon: <PiQuestionMarkFill />,
    path: '/eiken-materials',
    Component: EikenMaterialsPage,
    canAccess: (appScope, userRole) =>
      [AppScope.VR].includes(appScope) && [UserRole.Admin].includes(userRole),
  },
  {
    title: 'Locations',
    icon: <LocationIcon />,
    path: '/locations',
    Component: LocationsPage,
    canAccess: (appScope, userRole) =>
      [AppScope.Lite, AppScope.LiteBeginner].includes(appScope) &&
      [UserRole.Admin].includes(userRole),
  },
  {
    path: '/locations/new',
    Component: LocationEditPage,
    canAccess: (appScope, userRole) =>
      [AppScope.Lite, AppScope.LiteBeginner].includes(appScope) &&
      [UserRole.Admin].includes(userRole),
  },
  {
    path: '/locations/:id/edit',
    Component: LocationEditPage,
    canAccess: (appScope, userRole) =>
      [AppScope.Lite, AppScope.LiteBeginner].includes(appScope) &&
      [UserRole.Admin].includes(userRole),
  },
  {
    path: '/locations/:id',
    Component: LocationPage,
    canAccess: (appScope, userRole) =>
      [AppScope.Lite, AppScope.LiteBeginner].includes(appScope) &&
      [UserRole.Admin].includes(userRole),
  },
  {
    title: 'Feedback Levels',
    path: '/feedback-levels',
    Component: FeedbackLevelsPage,
    icon: (
      <path d="M20 2H4C2.9 2 2 2.9 2 4V16C2 17.1 2.9 18 4 18H8V21C8 21.6 8.4 22 9 22H9.5C9.7 22 10 21.9 10.2 21.7L13.9 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM11 13H7V8.8L8.3 6H10.3L8.9 9H11V13ZM17 13H13V8.8L14.3 6H16.3L14.9 9H17V13Z" />
    ),
    canAccess: (appScope, userRole) =>
      [AppScope.VR].includes(appScope) && [UserRole.Admin].includes(userRole),
  },
  {
    path: '/feedback-levels/new',
    Component: FeedbackLevelEditPage,
    canAccess: (appScope, userRole) =>
      [AppScope.VR].includes(appScope) && [UserRole.Admin].includes(userRole),
  },
  {
    path: '/feedback-levels/:id/edit',
    Component: FeedbackLevelEditPage,
    canAccess: (appScope, userRole) =>
      [AppScope.VR].includes(appScope) && [UserRole.Admin].includes(userRole),
  },
  {
    title: 'Practice History',
    path: '/chats',
    icon: <PiChatsFill />,
    Component: ChatsPage,
    canAccess: (appScope, userRole) =>
      [AppScope.VR].includes(appScope) &&
      [UserRole.Admin, UserRole.Teacher].includes(userRole),
  },
  {
    path: '/chats/:id',
    Component: ChatPage,
    canAccess: (appScope, userRole) =>
      [AppScope.VR].includes(appScope) &&
      [UserRole.Admin, UserRole.Teacher].includes(userRole),
  },
];

function App() {
  const { user } = useContext(AuthContext);

  if (!cookie.get('token') && !user) {
    return (
      <Routes>
        <Route path="/login" Component={LoginPage} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    );
  }

  const scopedRoutes = routes.filter(
    (route) =>
      user?.data.role && route.canAccess?.(BaseResource.scope, user.data.role)
  );

  return <Layout routes={scopedRoutes} />;
}

export default App;
