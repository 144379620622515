import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import { Card } from '@mui/material';
import PassFail from '../../design/passfail/PassFail';
import { ParsedScore } from '../../resources/ChatResource';

interface Props {
  score: ParsedScore;
  children?: React.ReactNode;
}

export default function ScoreCard({ score, children }: Props) {
  return (
    <Card sx={{ borderRadius: '12px' }} className="flex flex-col gap-4">
      <Box className="flex flex-row">
        <Box className="flex flex-col gap-3" sx={{ width: '150px' }}>
          <Typography variant="h6">{score.label}</Typography>
          <Box className="flex flex-row gap-2 items-end">
            <Typography variant="h3">
              {score.percentage}
              {score.unit}
            </Typography>
            {score.maxScore && (
              <Typography variant="body1">
                ({score.score}/{score.maxScore})
              </Typography>
            )}
          </Box>

          <PassFail type={score.type} />
        </Box>
        <Box className="flex flex-col gap-2" sx={{ flex: 0, flexGrow: 1 }}>
          <Typography
            variant="body2"
            color="text.secondary"
            textAlign="justify"
          >
            {score.description}
          </Typography>
        </Box>
      </Box>
      {children && children}
    </Card>
  );
}
