import BaseResource from './BaseResource';
import { ChatData } from './ChatResource';
import { StatisticData } from './StatisticResource';

export interface StudentData {
  id: string;
  firstName: string;
  lastName: string;
  avatar: string;
  recentActivities: StatisticData[];
  latestChat: ChatData;
}

class StudentResource extends BaseResource<StudentData> {
  static get storeName(): string {
    return 'StudentResource';
  }

  static get endpoint(): string {
    return `/${this.scope}/students`;
  }

  static toObject(raw: any): StudentResource {
    return new StudentResource(raw as StudentData);
  }
}

export default StudentResource;
