/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { Page, Searchbar, Table } from '../../design';
import { useRequester } from '../../hooks';
import { useEffect, useState, useContext } from 'react';
import { StatisticResource, StudentResource } from '../../resources';
import { AuthContext } from '../../contexts';
import { Aggregation } from '../../resources/StatisticResource';
import { Statistic } from '../../resources/StatisticResource';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

interface Row {
  id: string;
  name: string;
  avatar: string;
  average?: number;
  [key: string]: any; // Week numbers
}

function NameCell(props: Row) {
  return (
    <Box className="flex items-center gap-4">
      <Avatar src={props.avatar} />
      <Typography variant="h6" color="text.primary">
        {props.name}
      </Typography>
    </Box>
  );
}

function WeeklyStatHeader({ label }: { label: string }) {
  const { value, startDate, endDate } = StatisticResource.parseId(label);

  return (
    <Box sx={{ fontSize: '10px', fontWeight: 500, lineHeight: 1.5 }}>
      WEEK {value}
      <br />
      {moment(startDate).format('M/DD')} - {moment(endDate).format('M/DD')}
    </Box>
  );
}

function AverageStatHeader() {
  return (
    <Box sx={{ fontSize: '10px', fontWeight: 500, lineHeight: 1.5 }}>
      AVERAGE
      <br />
      (WEEK・MINUTE)
    </Box>
  );
}

function getColumns() {
  const today = new Date();
  const sixWeeksAgo = new Date(today.getTime() - 6 * 7 * 24 * 60 * 60 * 1000);

  const ids = StatisticResource.buildIds(
    sixWeeksAgo,
    today,
    Statistic.EikenStudyTimes,
    Aggregation.Week
  );

  const statColumns = ids.map((id) => {
    const { value } = StatisticResource.parseId(id);
    return {
      id: `week-${value}`,
      label: id,
      HeaderComponent: WeeklyStatHeader,
      align: 'center',
      format: (value: number) => value?.toFixed(0),
    };
  });

  return [
    { id: 'name', label: 'NAME', minWidth: 120, Component: NameCell },
    ...statColumns,
    {
      id: 'average',
      label: 'AVERAGE',
      align: 'center',
      HeaderComponent: AverageStatHeader,
    },
  ];
}

const toRow = (ob: StudentResource): Row => {
  const data = ob.data;

  const stats: any = {};
  let total = 0;
  for (const stat of data.recentActivities) {
    const { value } = StatisticResource.parseId(stat.id);
    stats[`week-${value}`] = stat.sum / 60;
    total += stat.sum / 60;
  }

  return {
    id: data.id,
    name: `${data.firstName} ${data.lastName}`,
    avatar: data.avatar,
    average:
      data.recentActivities.length > 0 ? (total / 7).toFixed(1) : undefined,
    ...stats,
  };
};

type Query = {
  qs?: string;
  offset: number;
  limit: number;
};

export default function StudentsPage() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [hasReachedBottom, setHasReachedBottom] = useState(false);
  const [query, setQuery] = useState<Query>({ offset: 0, limit: 50 });
  const studentsApi = useRequester<StudentResource[]>({
    onSubmit: (q) => StudentResource.fetchMany(q),
    onSuccess: (data) => setHasReachedBottom(data.length < query.limit),
    onError: console.log,
  });

  useEffect(() => {
    studentsApi.submit(query);
  }, [query.qs, query.offset]);

  const rows = StudentResource.list().map(toRow);
  rows.sort((a, b) => {
    if (a.average && b.average) {
      if (a.average === b.average) {
        return a.name.localeCompare(b.name);
      }
      return b.average - a.average;
    } 
    if (a.average) {
      return -1;
    }
    if (b.average) {
      return 1;
    }
    return a.name.localeCompare(b.name);
  });

  return (
    <Page>
      <Page.Header>
        <Box className="flex flex-col h-full">
          <Typography variant="body1" color="text.secondary" sx={{ mt: 8 }}>
            👋 Hey, {user?.data.firstName}!
          </Typography>
          <Typography variant="h1">
            Welcome to your student dashboard
          </Typography>
          <Box className="flex-grow"></Box>
          <Box>
            <Tabs value="all">
              <Tab label="All Students" value="all" />
            </Tabs>
          </Box>
        </Box>
      </Page.Header>
      <Page.Content>
        <Box className="flex justify-between mt-8 mb-8">
          <Box className="flex gap-4">
            <Searchbar
              placeholder="Search for a student"
              onChange={(qs) => setQuery({ qs, offset: 0, limit: 50 })}
            />

            {studentsApi.loading && (
              <Box className="flex items-center">
                <CircularProgress size={20} />
              </Box>
            )}
          </Box>
        </Box>

        <Table
          rows={rows}
          // @ts-ignore
          columns={getColumns()}
          onRowClick={(row) => navigate(`/students/${row.id}`)}
        />
        {!hasReachedBottom && rows.length > 0 && (
          <Button
            disabled={studentsApi.loading}
            sx={{ mt: 2 }}
            fullWidth
            onClick={() =>
              setQuery({ ...query, offset: query.offset + query.limit })
            }
          >
            Load More
          </Button>
        )}
      </Page.Content>
    </Page>
  );
}
