import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider, DataProvider, DialogProvider } from './contexts';

import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface TypeBackground {
    1: string;
    2: string;
    primary: string;
  }

  interface TypeText {
    1: string;
    2: string;
    3: string;
    primary: string;
    success: string;
    warning: string;
  }

  interface PaletteOptions {
    border: {
      1: string;
      primary: string;
      success: string;
      warning: string;
    };
  }
}

export const plColors = {
  background: {
    1: '#ffffff',
    2: '#f9f9f9',
    primary: '#D1F1FF',
  },
  text: {
    1: '#252525',
    2: '#454545',
    3: '#6E6E6E',
    4: '#919191',
    primary: '#252525',
    success: '#098061',
    warning: '#AD4A04',
    secondary: '#919191',
  },
  primary: {
    main: '#005C83',
    1: '#005C83',
    2: '#72D2FB',
  },
  success: {
    main: '#66DFC0',
  },
  warning: {
    main: '#FFAA6D',
  },
  border: {
    1: '#E9E9E9',
    primary: '#4DCAFF',
    success: '#3CC9A5',
    warning: '#EB843A',
  },
};

const theme = createTheme({
  palette: plColors,
  typography: {
    fontFamily: 'Noto Sans',
    h1: {
      fontSize: '40px',
      fontWeight: 600,
      lineHeight: '56px',
    },
    h2: {
      fontSize: '32px',
      fontWeight: 600,
    },
    h3: {
      fontSize: '24px',
      fontWeight: 600,
    },
    h4: {
      fontSize: '20px',
      fontWeight: 600,
    },
    h5: {
      fontSize: '16px',
      fontWeight: 500,
    },
    h6: {
      fontSize: '14px',
      fontWeight: 500,
    },
    body1: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    body2: {
      fontSize: '14px',
      lineHeight: '20px',
    },
    caption: {
      fontSize: '12px',
    },
    button: {
      fontSize: '16px',
      fontWeight: 600,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '24px',
          borderRadius: '24px',
          boxShadow: '2px 4px 24px -4px #0000001A',
          border: '1px solid #E9E9E9',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '14px',
          '&.Mui-selected': {
            color: '#252525',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: '3px',
          backgroundColor: '#72D2FB',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          boxShadow: '1px 2px 24px -4px #0000001A',
          borderBottom: '1px solid #E9E9E9',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: '24px',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '14px',
          fontWeight: 500,
          padding: '6px 12px',
          color: plColors.text[4],
          '&.Mui-selected': {
            color: plColors.primary.main,
            backgroundColor: plColors.primary[2],
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          margin: '-20px',
          padding: '20px',
          width: 'unset',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
          borderSpacing: '0 12px',
          border: 'none',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& tr': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
          '& th': {
            textAlign: 'center',
            fontSize: '14px',
            fontWeight: 600,
            color: plColors.text[3],
            padding: '16px',
            letterSpacing: '0.05em',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          borderRadius: '24px',
          boxShadow: '2px 4px 24px 0px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: 'none',
          background: 'transparent',
          padding: '24px 16px',

          '&:first-child': {
            borderTopLeftRadius: '24px',
            borderBottomLeftRadius: '24px',
          },
          '&:last-child': {
            borderTopRightRadius: '24px',
            borderBottomRightRadius: '24px',
          },
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ThemeProvider theme={theme}>
    <AuthProvider>
      <BrowserRouter>
        <DataProvider>
          <DialogProvider>
            <App />
          </DialogProvider>
        </DataProvider>
      </BrowserRouter>
    </AuthProvider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
