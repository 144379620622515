import { Box } from "@mui/material";

import { Typography } from "@mui/material";

export interface Phoneme {
  word: string;
  is_correct: boolean;
  is_correct_grade: number;
  boundaries: {
    min: number;
    max: number;
  };
  quality_score: number;
  transcription: string;
  english: boolean;
  source_word: string;
  source_word_continue: boolean;
}

interface Props {
  referenceText: string;
  phonemes: Phoneme[];
}

export default function Phonemes({ referenceText: paragraph, phonemes }: Props) {
  if (!paragraph || !phonemes) {
    return (
      <Typography variant="body1" color="text.secondary">
        No data available
      </Typography>
    );
  }

  const extractWord = (input: string): string | null => {
    const match = input.match(/\w+/);
    return match ? match[0] : null;
  };

  const paragraphWords = paragraph.split(" ");
  const paragraphWordsPhonemes = [];
  let j = 0;
  for (let i = 0; i < paragraphWords.length; i++) {
    while (phonemes[j].source_word_continue) {
      j++;
    }
    paragraphWordsPhonemes.push({
      word: paragraphWords[i],
      wordExtracted: extractWord(paragraphWords[i]),
      phonemes: phonemes[j],
      phonemesWord: phonemes[j].word
    });
    j++;
  }

  const givePhonemeColor = (phoneme: Phoneme) => {
    if (!phoneme.english) {
      return "border.1";
    } else if (phoneme.is_correct_grade === 5) {
      return "border.success";
    } else if (phoneme.is_correct_grade === 4) {
      return "";
    } else {
      return "border.warning";
    }
  }

  return (
    <Box className="flex flex-col gap-6">
      <Box className="flex flex-row gap-8 items-center">
        <Box className="flex flex-row gap-8 items-center">
          {["Perfect", "Failed", "Not Scored"].map((label) => (
            <Box key={label} className="flex flex-row gap-2 items-center">
              <Box
                sx={{
                  width: "24px",
                  height: "24px",
                  backgroundColor:
                    label === "Perfect"
                      ? "border.success"
                      : label === "Failed"
                      ? "border.warning"
                      : "border.1",
                  borderRadius: 1,
                }}
              ></Box>
              <Typography variant="h5" color="text.3">
                {label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box display="flex" rowGap={1} flexWrap="wrap">
        {
          paragraphWordsPhonemes.map((paragraphWordsPhoneme, index) => {
            return <Typography 
              key={index}
              className="px-1"
              component="span" 
              variant="body1" 
              color="text.3" 
              sx={{
                backgroundColor: givePhonemeColor(paragraphWordsPhoneme.phonemes),
              }}>
                {paragraphWordsPhoneme.word}
            </Typography>;
          })
        }
      </Box>
    </Box>
  );
}