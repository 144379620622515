import { createContext, useEffect, useState } from 'react';
import cookie from 'js-cookie';
import { UserResource } from '../resources';

interface IAuthContext {
  user: UserResource | null;
  setUser: (user: UserResource | null) => void;
}

export const AuthContext = createContext<IAuthContext>({
  user: null,
  setUser: () => {},
});

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [user, setUser] = useState<UserResource | null>(null);

  useEffect(() => {
    if (cookie.get('token')) {
      UserResource.refresh().then(setUser).catch(console.log);
    }
  }, []);

  const scope = UserResource.scope;
  const userId = user?.data.id;

  useEffect(() => {
    // User object is not available in the first render
    if (!userId) return;

    const accessList = user.data.access || [];
    if (accessList.length > 0 && !accessList.includes(scope)) {
      // If the selected scope is not in the access list,
      // set the first scope in the access list as selected scope
      UserResource.setScope(accessList[0]);
    }
  }, [scope, userId]);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
}
