import * as React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  SvgIcon,
} from '@mui/material';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Menu } from '../design';
import { BaseResource } from '../resources';
import { SCOPE_LABEL_MAP } from '../resources/BaseResource';
import { plColors } from '..';
import { AuthContext } from '../contexts';
import { useContext } from 'react';

const drawerWidth = 195;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const CustomDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const CustomListItemButton = styled(ListItemButton)(({ theme }) => ({
  minHeight: '40px',
  padding: '4px 12px',
  borderRadius: '8px',
  fontWeight: 500,
  '.MuiListItemText-root': {
    color: plColors.text[4],
  },
  '.MuiListItemIcon-root': {
    color: plColors.text[4],
  },
  '&.Mui-selected': {
    backgroundColor: plColors.background.primary,
    '.MuiListItemText-root': {
      color: plColors.primary.main,
    },
    '.MuiListItemIcon-root': {
      color: plColors.border.primary,
    },
  },
  '.MuiTypography-root': {
    fontSize: '14px',
    fontWeight: 500,
  },
}));

type Item = {
  title: string;
  path: string;
  icon: React.ReactNode;
};

type Props = {
  open: boolean;
  onClose: () => void;
  items: Item[];
};

export default function Drawer({ open, onClose, items }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(AuthContext);

  const scopes = user?.data.access || [];

  return (
    <CustomDrawer variant="permanent" open={open}>
      <DrawerHeader>
        <Menu
          anchor={
            <Button
              disabled={scopes.length < 2}
              label={SCOPE_LABEL_MAP[BaseResource.scope]}
              sx={{ ml: 1.5 }}
            />
          }
          items={scopes
            .filter((scope) => scope !== BaseResource.scope)
            .map((scope) => ({
              label: SCOPE_LABEL_MAP[scope],
              name: scope,
              onSelect: () => BaseResource.setScope(scope),
            }))}
        />
        <Box flexGrow={1}></Box>
        <IconButton onClick={onClose}>
          <ChevronRightIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {items.map((item) => (
          <ListItem key={item.path} sx={{ display: 'block', px: 1, py: 0.5 }}>
            <CustomListItemButton
              selected={location.pathname === item.path}
              sx={{ justifyContent: open ? 'initial' : 'center' }}
              onClick={() => navigate(item.path)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 1 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <SvgIcon>{item.icon}</SvgIcon>
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </CustomListItemButton>
          </ListItem>
        ))}
      </List>
    </CustomDrawer>
  );
}
